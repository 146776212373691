import React from 'react';

const DummyContent = ({ title, form }) => {
  const renderContent = () => {
    if (title === "Policy") {
      return (
        <div style={{ textAlign: 'justify', marginLeft: '10px', padding: "35px" }}>
          <h1>{title}</h1>
          <br />
          <h2>Introduction</h2>
          <p>
            At AI Shop, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our eCommerce website AI Shop.
          </p>
          <h2>Information Collection</h2>
          <p>
            We may collect personal identification information (such as name, email address, mailing address, phone number, and payment information) when you place an order, subscribe to our newsletter, fill out a form, or enter information on our site.
          </p>
          <h2>Information Use</h2>
          <p>
            We may use the information we collect from you to process transactions, send periodic emails, improve our website, and respond to customer service requests.
          </p>
          <h2>Information Protection</h2>
          <p>
            We implement a variety of security measures to maintain the safety of your personal information. Your personal information is contained.
          </p>
        </div>
      );
      
    } else if (title === "Shipping") {
      return (
        <div style={{ textAlign: 'justify', marginLeft:'10px' }}>
          <h1>{title}</h1>
          <br></br>
          <h2>General Information</h2>
          <p>
            All orders are subject to product availability. If an item is not in stock at the time you place your order, we will notify you and refund you the total amount of your order using the original method of payment.
          </p>
          <h2>Delivery Location</h2>
          <p>
            Items offered on our website are only available for delivery to addresses in India. Any shipments outside of India are not available at this time.
          </p>
          <h2>Delivery Time</h2>
          <p>
            An estimated delivery time will be provided to you once your order is placed. Delivery times are estimates and commence from the date of shipping, rather than the date of order. Delivery times are to be used as a guide only and are subject to the acceptance and approval of your order.
          </p>
          <p>
            Standard Shipping: 5-7 business days<br />
            Express Shipping: 2-3 business days<br />
            Please note that delivery times do not include weekends and holidays. Delivery dates are not guaranteed and delays can occur due to unforeseen circumstances.
          </p>
          <h2>Shipping Costs</h2>
          <p>
            Shipping costs are based on the weight of your order and the delivery method. To find out how much your order will cost, simply add the items you would like to purchase to your cart and proceed to the checkout page. Once at the checkout screen, shipping charges will be displayed.
          </p>
          <p>
            Standard Shipping: ₹100 for orders above ₹50 (Free for orders over ₹50)<br />
            Express Shipping: ₹150 for all orders
          </p>
          <h2>Order Tracking</h2>
          <p>
            Once your order has shipped, we will send you a confirmation email with your tracking number and a link to track your order.
          </p>
          <h2>Damaged Items in Transport</h2>
          <p>
            If there is any damage to the packaging on delivery, contact us immediately at [Customer Service Email] and provide the details of the damaged product along with your order number.
          </p>
          <h2>Lost or Stolen Packages</h2>
          <p>
            We are not responsible for lost or stolen packages confirmed to be delivered to the address entered for an order. Upon inquiry, we will confirm delivery to the address provided, date of delivery, tracking information, and shipping carrier information for the customer to investigate.
          </p>
          <h2>International Shipping</h2>
          <p>
            Currently, we do not offer international shipping.
          </p>
          <h2>Questions</h2>
          <p>
            If you have any questions about the delivery and shipment of your order, please contact us at medcall@yoodobuzz.com.
          </p>
        </div>
      );
    }else if(title === "Return"){
      return (
        <div style={{ textAlign: 'justify', marginLeft:'10px' }}>
       <h1>{title}</h1>
          <br></br>
     <h2>Return & Refund Policy</h2> 

         <p>Refund is provided for prepaid orders only. Your refund will be initiated within 3-5 working days of receiving the refund request and authorization of the refund. The refund amount will be credited back into the same account (online banking, credit card, debit card, or whichever available) that was used to make the purchase. 
         </p>
    </div>
      )
    }else if(title === "Support Center"){
      return (
        <div style={{ textAlign: 'justify', marginLeft:'10px' }}>
      <h2>Support Center</h2>
            <p>Welcome to our Support Center. Here you can find answers to common questions and get help with your account, orders, and more.</p>
            <h3>FAQs</h3>
            <p>Find answers to frequently asked questions about our products, services, and policies.</p>
            <ul>
              <li>How can I track my order?</li>
              <li>What is your return policy?</li>
              <li>How do I contact customer support?</li>
            </ul>
            <h3>Contact Us</h3>
            <p>If you can't find the answer to your question in our FAQs, please contact us for further assistance.</p>
            <h3>Policy</h3>
            <p>Read our policies regarding shipping, returns, and other important topics.</p>
      
    </div>
      )
    }

    else if(title === "Term & Conditions"){
      return (
        <div style={{ textAlign: 'justify', marginLeft:'10px' }}>
      <h2>Terms & Conditions</h2>
            <p>These terms and conditions outline the rules and regulations for the use of Medcall's Website, located at www.medcall.com.</p>
            <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use Medcall if you do not agree to take all of the terms and conditions stated on this page.</p>
            <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves.</p>
            <h3>Cookies</h3>
            <p>We employ the use of cookies. By accessing Medcall, you agreed to use cookies in agreement with the Medcall's Privacy Policy.</p>
            <h3>License</h3>
            <p>Unless otherwise stated, Medcall and/or its licensors own the intellectual property rights for all material on Medcall. All intellectual property rights are reserved. You may access this from Medcall for your own personal use subjected to restrictions set in these terms and conditions.</p>
            <h3>Restrictions</h3>
            <p>You are specifically restricted from all of the following:</p>
            <ul>
              <li>publishing any Website material in any other media;</li>
              <li>selling, sublicensing, and/or otherwise commercializing any Website material;</li>
              <li>publicly performing and/or showing any Website material;</li>
              <li>using this Website in any way that is or may be damaging to this Website;</li>
              <li>using this Website in any way that impacts user access to this Website;</li>
              <li>using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;</li>
            </ul>
            <p>For the full Terms & Conditions, please visit our website.</p>
      
    </div>
      )
    }

    else if(title === "FAQs"){
      return (
        <div style={{ textAlign: 'justify', marginLeft:'10px' }}>
      <h2>Frequently Asked Questions (FAQ)</h2>
            <p>These terms and conditions outline the rules and regulations for the use of Medcall's Website, located at www.medcall.com.</p>
            <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use Medcall if you do not agree to take all of the terms and conditions stated on this page.</p>
            <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves.</p>
            <h3>Cookies</h3>
            <p>We employ the use of cookies. By accessing Medcall, you agreed to use cookies in agreement with the Medcall's Privacy Policy.</p>
            <h3>License</h3>
            <p>Unless otherwise stated, Medcall and/or its licensors own the intellectual property rights for all material on Medcall. All intellectual property rights are reserved. You may access this from Medcall for your own personal use subjected to restrictions set in these terms and conditions.</p>
            <h3>Restrictions</h3>
            <p>You are specifically restricted from all of the following:</p>
            <ul>
              <li>publishing any Website material in any other media;</li>
              <li>selling, sublicensing, and/or otherwise commercializing any Website material;</li>
              <li>publicly performing and/or showing any Website material;</li>
              <li>using this Website in any way that is or may be damaging to this Website;</li>
              <li>using this Website in any way that impacts user access to this Website;</li>
              <li>using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;</li>
            </ul>
            <p>For the full Terms & Conditions, please visit our website.</p>
      
    </div>
      )
    }

    else if(title === "About Us"){
      return (
        <div style={{ textAlign: 'justify', marginLeft:'10px' }}>
      <h2>About Us</h2>
      <h2>Welcome to Medcall</h2>
<p>At Medcall, we are dedicated to providing exceptional healthcare services to our community. Our mission is to improve the health and well-being of every individual we serve through compassion, innovation, and excellence in care.</p>

<h2>Our Story</h2>
<p>Founded in [Year], Medcall has grown from a small clinic to a leading healthcare provider in the region. Our journey began with a vision to redefine healthcare delivery and make a positive impact on people's lives.</p>

<h2>Our Services</h2>
<p>We offer a wide range of healthcare services, including general medicine, specialized treatments, preventive care, and wellness programs. Our team of experienced healthcare professionals is committed to delivering personalized care tailored to meet the unique needs of each patient.</p>

<h2>Our Team</h2>
<p>Our team consists of skilled doctors, nurses, and support staff who are passionate about providing high-quality healthcare services. Together, we work collaboratively to ensure that our patients receive the best possible care.</p>

<h2>Our Commitment</h2>
<p>At Medcall, we are committed to upholding the highest standards of patient care, safety, and ethics. We strive to create a welcoming and inclusive environment where everyone feels respected, valued, and cared for.</p>

<h2>Contact Us</h2>
<p>We look forward to serving you and your family. Contact us today to learn more about our services or to schedule an appointment.</p>

      
    </div>
      )
    }

    else if(title === "Career"){
      return (
        <div style={{ textAlign: 'justify', marginLeft:'10px' }}>
      <h2>Career</h2>
      <h2>Careers at Medcall</h2>
<p>Join our team of dedicated healthcare professionals who are committed to making a difference in people's lives. At Medcall, we offer a supportive work environment, opportunities for growth and development, and the chance to work with cutting-edge technology in healthcare.</p>
<h3>Current Openings</h3>
<p>We are currently seeking talented individuals to fill the following positions:</p>
<ul>
  <li><strong>Registered Nurse:</strong> Provide compassionate care to patients in our hospital setting. Must be licensed and have clinical experience.</li>
  <li><strong>Medical Assistant:</strong> Assist physicians with patient care, including taking vital signs, preparing exam rooms, and documenting patient information.</li>
  <li><strong>Physician:</strong> Board-certified physician to join our team and provide high-quality care to patients in our clinic.</li>
  <li><strong>Administrative Assistant:</strong> Provide administrative support to our healthcare team, including scheduling appointments, answering phones, and maintaining patient records.</li>
</ul>
<h3>Why Join Us?</h3>
<p>At Medcall, we believe in investing in our employees and providing them with opportunities to grow and succeed. We offer competitive salaries, comprehensive benefits packages, and a supportive work environment where your contributions are valued.</p>
<h3>How to Apply</h3>
<p>If you are interested in joining our team, please email your resume and cover letter to careers@medcall.com. Be sure to include the position you are applying for in the subject line of your email.</p>
<p>We look forward to hearing from you!</p>
      
    </div>
      )
    }

    else if (title === "Contact Us") {
      return (
        <div style={{ textAlign: 'justify', marginLeft:'10px' }}>
          <h2>Contact Us</h2>
          <p>Thank you for your interest in Medcall. If you have any questions about our services or would like to schedule an appointment, please don't hesitate to contact us. Our friendly and knowledgeable staff are here to assist you.</p>
          <h3>Address</h3>
          <p>Medcall Healthcare Center<br />123 Main Street<br />City, State, ZIP Code</p>
          <h3>Phone</h3>
          <p>General Inquiries: (123) 456-7890<br />Appointments: (123) 456-7891</p>
          <h3>Email</h3>
          <p>info@medcall.com</p>
          <h3>Hours of Operation</h3>
          <p>Monday - Friday: 8:00am - 6:00pm<br />Saturday: 9:00am - 3:00pm<br />Sunday: Closed</p>
          <h3>Connect With Us</h3>
          <p>Stay connected with us on social media for the latest news and updates:</p>
          <ul>
            <li><a href="#">Facebook</a></li>
            <li><a href="#">Twitter</a></li>
            <li><a href="#">Instagram</a></li>
          </ul>
          <h3>Feedback</h3>
          <p>We value your feedback. If you have any comments, suggestions, or concerns, please fill out the form below and we will get back to you as soon as possible.</p>
        </div>
      );
    }

    else if (title === "Affiliate") {
      return (
        <div style={{ textAlign: 'justify', marginLeft:'10px' }}>
          <h2>Join Our Affiliate Program</h2>
<p>At Medcall, we believe in collaborative partnerships that benefit everyone involved. Our affiliate program is designed to reward partners who help us reach more people in need of quality healthcare services.</p>
<h3>Why Partner With Us?</h3>
<ul>
  <li><strong>Competitive Commissions:</strong> Earn generous commissions for every successful referral.</li>
  <li><strong>High Conversion Rates:</strong> Benefit from our trusted brand and high-quality services.</li>
  <li><strong>Dedicated Support:</strong> Access to a dedicated affiliate manager who will assist you every step of the way.</li>
  <li><strong>Marketing Materials:</strong> Get access to a range of marketing materials to help you promote our services effectively.</li>
</ul>
<h3>How It Works</h3>
<ol>
  <li><strong>Sign Up:</strong> Fill out our simple application form to join our affiliate program.</li>
  <li><strong>Promote:</strong> Start promoting Medcall using your unique affiliate link or marketing materials.</li>
  <li><strong>Earn:</strong> Earn commissions for every referral that results in a successful appointment or service.</li>
</ol></div>
      );
    }
    
    else if (title === "Sell on Market Place") {
      return (
        <div style={{ textAlign: 'justify', marginLeft:'10px' }}>
           <h2>Sell on Market Place</h2>
           <br></br>

<form>
        
          <div>
            <label>Name : </label>
            <input style={{marginLeft:'22px'}} className='form_foot' type="text" name="name" />
          </div>
          <br></br>
          <div>
            <label>Email : </label>
            <input style={{marginLeft:'22px'}} className="form_foot" type="email" name="email" />
          </div>
          <br></br>
          <div>
            <label>Message : </label>
            <input type="text" className="form_foot" name="message" />
          </div>
          <br></br>
          <button className='btn btn-primary' style={{marginLeft:'10%'}} type="submit">Submit</button>
        </form>
          <p>Welcome to Medcall Marketplace! Join our platform to reach a large audience of healthcare consumers looking for quality products and services. Whether you offer medical supplies, equipment, or healthcare-related services, our marketplace provides a valuable opportunity to expand your reach and grow your business.</p>
<h3>Why Sell on Medcall Marketplace?</h3>
<ul>
  <li><strong>Access to a Large Audience:</strong> Reach thousands of healthcare consumers actively looking for products and services.</li>
  <li><strong>Grow Your Business:</strong> Expand your customer base and increase sales by selling on our platform.</li>
  <li><strong>Trusted Platform:</strong> Benefit from our reputation as a trusted healthcare provider, enhancing your credibility among customers.</li>
  <li><strong>Easy Setup:</strong> Setting up your store on Medcall Marketplace is quick and easy, allowing you to start selling in no time.</li>
</ul>
<h3>How It Works</h3>
<ol>
  <li><strong>Register:</strong> Create an account on Medcall Marketplace as a seller.</li>
  <li><strong>List Your Products:</strong> Upload your products, including descriptions, images, and prices.</li>
  <li><strong>Manage Your Store:</strong> Monitor sales, manage inventory, and communicate with customers through our seller dashboard.</li>
  <li><strong>Grow Your Business:</strong> Optimize your listings, participate in promotions, and engage with customers to grow your business on our platform.</li>
</ol>
<h3>Join Us Today!</h3>
<p>Don't miss this opportunity to showcase your products and services to a vast audience of healthcare consumers. Join Medcall Marketplace today and start selling!</p></div>
      );
    }

    else if (title === "Advertises on Market Place") {
      return (
        <div style={{ textAlign: 'justify', marginLeft:'10px' }}>
<h2>Advertises on Market Place</h2>
<br></br>

<form>
        
          <div>
            <label>Name : </label>
            <input style={{marginLeft:'22px'}} className='form_foot' type="text" name="name" />
          </div>
          <br></br>
          <div>
            <label>Email : </label>
            <input style={{marginLeft:'22px'}} className="form_foot" type="email" name="email" />
          </div>
          <br></br>
          <div>
            <label>Message : </label>
            <input type="text" className="form_foot" name="message" />
          </div>
          <br></br>
          <button className='btn btn-primary' style={{marginLeft:'10%'}} type="submit">Submit</button>
        </form>
          <p>Advertise your products and services on Medcall Marketplace to reach a targeted audience of healthcare consumers. Whether you're looking to promote a new product, drive traffic to your store, or increase brand awareness, our advertising options can help you achieve your goals.</p>
<h3>Why Advertise on Medcall Marketplace?</h3>
<ul>
  <li><strong>Targeted Audience:</strong> Reach healthcare consumers who are actively searching for products and services like yours.</li>
  <li><strong>Increased Visibility:</strong> Stand out from competitors and increase visibility for your brand.</li>
  <li><strong>Flexible Options:</strong> Choose from a variety of advertising options to suit your budget and objectives.</li>
  <li><strong>Trackable Results:</strong> Monitor the performance of your ads in real-time and optimize for better results.</li>
</ul>
<h3>Advertising Options</h3>
<p>Choose from the following advertising options to promote your products and services on Medcall Marketplace:</p>
<ul>
  <li><strong>Sponsored Products:</strong> Promote your products in search results and on product detail pages.</li>
  <li><strong>Banner Ads:</strong> Display banner ads on relevant pages to increase visibility.</li>
  <li><strong>Featured Listings:</strong> Highlight your products with featured listings for increased exposure.</li>
</ul>
<h3>Get Started Today!</h3>
<p>Don't miss out on the opportunity to reach a targeted audience of healthcare consumers. Advertise on Medcall Marketplace today and start seeing results!</p>
</div>
      );
    }



    else {
      return <h2> {title}.</h2>;
    }
  };

  return (
    <div>
      
      {form ? (
        <form style={{ textAlign: 'justify', marginLeft:'10px' }}>
          <h2>Contact Us & Register a Seller</h2>
      <br></br>
          <div>
            <label>Name : </label>
            <input style={{marginLeft:'22px'}} className='form_foot' type="text" name="name" />
          </div>
          <br></br>
          <div>
            <label>Email : </label>
            <input style={{marginLeft:'22px'}} className="form_foot" type="email" name="email" />
          </div>
          <br></br>
          <div>
            <label>Message : </label>
            <input type="text" className="form_foot" name="message" />
          </div>
          <br></br>
          <button className='btn btn-primary' style={{marginLeft:'10%'}} type="submit">Submit</button>
        </form>
      ) : (
        renderContent()
      )}
    </div>
  );
};

export default DummyContent;
